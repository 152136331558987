import React, { useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import { useLocation } from '@reach/router';

const Breadcrumbs: React.FC = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const [hasNavigated, setHasNavigated] = useState(false);

  useEffect(() => {
    // Détecter les changements de l'emplacement pour savoir si l'utilisateur a navigué
    setHasNavigated(true);
  }, [location.pathname]);

  const arrow = "<"

  return (
    <nav className="breadcrumb is-medium has-succeeds-separator" aria-label="breadcrumbs">
      <div className="breadcrumb-container is-flex is-flex-direction-row is-align-items-center is-flex-wrap-nowrap is-justify-content-flex-end" style={{ height: 'var(--navbar-height)' }}>
        <div             style={{marginRight:'auto'}}>
          {hasNavigated && pathnames.length > 0 && (
            <button
              className="button is-primary is-link is-small"
              onClick={() => navigate(-1)}
            >
              <span className="has-text-white">{arrow} GO BACK </span> 
            </button>
          )}
        </div>
        <ul className="is-size-7 mr-4" style={{marginTop: 0, marginBottom: 0}}>
          <li  className="is-current has-text-grey">
            <Link to="/" className="is-active has-text-grey" >Home</Link>
          </li>
          {pathnames.map((value, index) => {
            const to = `/${pathnames.slice(0, index + 1).join('/')}`;
            const isLast = index === pathnames.length - 1;
            const truncatedValue = value.length > 8 ? value.substring(0, 8) + "..." : value;

            return (
              <li key={to} className="is-current has-text-grey">
                {isLast ? (
                  <span >
                    {decodeURIComponent(truncatedValue)}
                  </span>
                ) : (
                  <Link to={to} className="has-text-grey">
                    {decodeURIComponent(truncatedValue)}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
        
      </div>
    </nav>
  );
};

export default Breadcrumbs;
