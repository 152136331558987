import React from 'react';
import { AppProvider } from './src/context/AppContext';

export const wrapRootElement = ({ element }) => {
  return <AppProvider>{element}</AppProvider>;
};

export function shouldUpdateScroll({ routerProps: { location } }) {
  const { pathname } = location;
  
  // Vérifie si la page est une page de projet
  //const isProjectPage = pathname.startsWith('/projects/');

  //find tailsdiv
  const tailsDiv = document.querySelector('.lvbk-tails') as HTMLElement;
  const loadingDiv = document.querySelector('.lvbk-loader') as HTMLElement;
  
  //if (isProjectPage) {
    // Faire défiler vers le haut pour les pages de projet
    if (tailsDiv) {
      // Utiliser scrollTo pour faire défiler jusqu'au sommet de cette div
      tailsDiv.scrollTo(0, 0);
    }
    if (loadingDiv) {
      loadingDiv.style.display = 'none';
    }
  //}
  
  // Pour toutes les autres pages, laisser Gatsby gérer le défilement par défaut
  return true;
}