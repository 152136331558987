import React, { useRef, useEffect, useState, Suspense } from "react";
import '../styles/main.sass'
import '../styles/style.page.scss'
import Flower from "./html/items/flower.animation";
import { NavBar } from "./navbar";
import gsap from "gsap";
import { useLocation } from '@reach/router';
import { use100vh } from 'react-div-100vh';
import { DarkModeToggle, Mode } from '@anatoliygatt/dark-mode-toggle';
import styled from "styled-components";
import { useAppVariables } from "../context/AppContext";

const LayoutRoot: React.FC<LayoutRootProps> = (props: DefaultLayoutProps) => {
  const location = useLocation();
  const { showAbout, setIsHomepage } = useAppVariables();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [theme, setTheme] = React.useState<'light' | 'dark'>('light');
  useEffect(() => {
      // Déterminer le thème initial côté client
      if (typeof window !== 'undefined') {
          const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
          setTheme(defaultDark ? 'light' : 'light');
      }
  }, []);
 
  useEffect(() => {
    // Vérifier si l'URL correspond à la page d'accueil
    const isHomepage = location.pathname === '/';
    setIsHomepage(isHomepage);
  }, [location.pathname, setIsHomepage]);

   // Gère le comportement de la navbar sur la page d'accueil
   useEffect(() => {
    const lvbkTails = document.querySelector('.lvbk-tails');

    if (lvbkTails && location.pathname === '/') {
      const navbar = document.querySelector('.navbar');

      const handleScroll = () => {
        const scrollTop = lvbkTails.scrollTop;
        if (scrollTop >= 100) {
          navbar?.classList.add('otherpage');
          navbar?.classList.remove('homepage');
        } else {
          navbar?.classList.add('homepage');
          navbar?.classList.remove('otherpage');
        }
      };

      // Ajoute l'écouteur de défilement à .lvbk-tails
      lvbkTails.addEventListener('scroll', handleScroll);

      // Nettoie l'écouteur d'événements lors du démontage ou du changement de page
      return () => {
        lvbkTails.removeEventListener('scroll', handleScroll);
      };
    }
  }, [location.pathname]);
  //const toggleTheme = () => {
  //    const newTheme = theme === 'light' ? 'dark' : 'light';
  //    setTheme(newTheme);
  //};

  //const mode: Mode = theme === 'dark' ? 'light' : 'light';

  
  const hamburgerClick = () => {
    setIsMenuOpen(state => !state);
  };

  const navClick = () => {
    console.debug("navclick")
    if (isMenuOpen) {
      console.debug('navclick while isMenuOpen is true, we set isMenuOpen to false then')
      setIsMenuOpen(false)
    }
  };

  useEffect(() => {
    //console.debug('showAbout variable changing', showAbout)
    //hide3D()
    //setShowAbout(true)
  }, [showAbout]);






  const height = use100vh()!;
  const vhUnit: number | "1vh" = height ? (height / 100) : '1vh';
  const canvasHeight = height && vhUnit !== "1vh" ? (100 * vhUnit) : '100vh';

//  <Div style={{position:'absolute', bottom: '5vh', right: '5vw', zIndex: "9999999" }} onClick={toggleTheme}>
//  <DarkModeToggle mode={mode} size="sm" />
//</Div> 
  return (
    <>
        <div className="layout-root lvbk-primary" data-theme={theme}>
            <div className="lvbk-main-wrapper">
                <div className="lvbk-nav" style={{ zIndex: 5 }} >
                    <div>
                      <NavBar
                        navClick={() => navClick()} 
                        hamburgerClick={() => hamburgerClick()} 
                        isMenuOpen={isMenuOpen}
                      />
                    </div>
                    <div>
                        <Flower/>
                    </div>
                </div>
                <div className="lvbk-heads quickfix-heads">
                </div>
                <div className="lvbk-tails">
                    {props.children}
                </div>
            </div>
        </div>
    </>
  );
};


const Div = styled.div`
  position: absolute
  z-index: 99999999
  transform: scale(0.7);
  @media (min-width: 768px) {
    transform: scale(1)
  }
  @media (max-width: 768px) {
    transform: scale(0.7);
    bottom: 0.1rem !important;
    margin-inline-end: 0.375rem !important;
    right: 0 !important;
  }
`

export default LayoutRoot;

