import * as React from "react";
import { Link, useStaticQuery, graphql} from "gatsby";
import gsap from "gsap";
import { useEffect, useRef, useState } from 'react';
import Breadcrumbs from "./breadcrumbs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppVariables } from "../context/AppContext";
import { faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faCoffee } from "@fortawesome/free-solid-svg-icons";

interface NavBarProps {
  navClick: () => void;
  hamburgerClick: () => void;
  isMenuOpen: boolean;
}

interface DataGlobal {
    strapiSiteInformation?: Global;
}



const NavBar: React.FC<NavBarProps> = ({ hamburgerClick, navClick, isMenuOpen }) => {

  const { isHomepage } = useAppVariables();

  const isFirstRender = useRef(true);
  let data : DataGlobal = {
      strapiSiteInformation: undefined
  }
  data = useStaticQuery(graphql`
    query GlobalQuery {
      strapiSiteInformation {
        email
        slogan
        title
        description {
          data {
            description
          } 
        }
      }
    }
  `)
  const Refs = useRef<HTMLDivElement[]>([])
  const [isActive, setIsActive] = useState(false);


 
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (data) {
      console.log('debug nav info', data)
    }
    // close => opening
    if (!isActive) {
      console.debug('!isactive loop')
      // wait a bit before set is-active
      setTimeout(() => {
        setIsActive(true)
      }, 200)
    } 
    setTimeout(() => { 
      if (isMenuOpen) {
      // close => opening
        console.debug('IsMenuOpen loop when true')
          gsap.to(".navbar-menu", {y: '0', ease: "power4.inOut"});
      } else {
      // open => closing
      console.debug('IsMenuOpen loop when false')
        gsap.to(".navbar-menu", {y: '100%', ease: "power4.inOut"});
        setTimeout(() => {
          setIsActive(false)
        }, 200)
      }
    }, 200)
    Refs.current.forEach((ref, index) => {
      // close => opening 
      if (isMenuOpen) {
        console.debug('IsMenuOpen ref loop when true')
        setTimeout(() => {
              gsap.to(ref, { duration: 0.5, opacity: 1, y: 0, ease: 'power3.inOut', delay: index * 0.1 });
        }, 400)
      } else {
        console.debug('IsMenuOpen ref loop when false')
        // open => closing
          gsap.to(ref, { duration: 0.5, opacity: 0, y: 20, ease: 'power3.inOut', delay: index * 0.1 });
      }
    })
  }, [isMenuOpen]);
  //<CubeRotating isDarkBackground={true}/>
  const image_closedbox = process.env.BASE_URL + '/' + '/bloombox_close_wwwlaufaitdelartcom.png';
  const image_openbox = process.env.BASE_URL + '/' + '/bloombox_open_wwwlaufaitdelartcom.png';
  const width:number = 100

  return (
    <nav className={`navbar is-fixed-top lvbk-navbar-color ${isHomepage ?? true ? 'homepage' : 'otherpage'}`} role="navigation" aria-label="main navigation">
      <div className="container section" style={{paddingTop:'inherit', paddingBottom:'inherit'}}>
        <div className="navbar-brand lvbk-navbar-brand">
          <Link to={"/"} className="navbar-item lvbk-special-font is-size-4-mobile" onClick={navClick} style={{padding:0,backgroundColor:'transparent'}}>
              Laufaitdelart
          </Link>
          <div className="social-links navbar-item" style={{marginLeft: "auto"}}>
            <a href="https://www.instagram.com/lau.vwbk" target="_blank" className="">
              <FontAwesomeIcon icon={faInstagram} size="1x" />
            </a>
            <a href="https://www.linkedin.com/in/lauvwbk" target="_blank" className="ml-2">
              <FontAwesomeIcon icon={faLinkedinIn} size="1x"/>
            </a>
          </div>
          <a 
            role="button" 
            className={`navbar-burger ${isMenuOpen ? 'is-active' : ''}`} 
            aria-label="menu" 
            aria-expanded='false' 
            onClick={(e) => hamburgerClick()}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        {isHomepage ?? true ? 
          <div/>
          : 
          <div className="lvbk-breadcrumbs pb-2" style={{transform: "none", paddingTop: 0, paddingBottom: 0, borderRadius: '0 0 10px 10px'}}>
            <Breadcrumbs/>
          </div>
        }
        <div 
          id="lvbk-navbar" 
          className={`navbar-menu section ${isActive ? 'is-active' : ''}`}
          style={{marginLeft:0}}
        >
          <div className="navbar-start mb-5">
              
              <Link to={"/#projects"} className="navbar-item link title is-size-2-desktop is-size-3-tablet is-size-4-mobile" onClick={(e) => { navClick(); }}>
                <div ref={(el) => (Refs.current[2] = el!)} >
                  Portfolio
                </div>
              </Link>
              <Link to={"/#about"} className="navbar-item link title is-size-2-desktop is-size-3-tablet is-size-4-mobile" onClick={(e) => { navClick(); }}>
                <div ref={(el) => (Refs.current[1] = el!)} >
                  About
                </div>
              </Link>
              <Link to={"/#contact"} className="navbar-item link title is-size-2-desktop is-size-3-tablet is-size-4-mobile" onClick={(e) => { navClick(); }}>
                <div ref={(el) => (Refs.current[0] = el!)} >
                  Contact me
                </div>
              </Link>
              <div className="navbar-item title is-size-2-desktop is-size-3-tablet is-size-4-mobile" onClick={(e) => { navClick(); }}>
                <a href="https://buymeacoffee.com/laufaitdelart" target="_blank" className="">
                  Buy me a coffee
                </a>
              </div>
          
              <div className="columns">
                <div className="column is-three-quarters"/>

                <div className="column">
                    <figure className="image">
                      <img src={image_openbox} />
                    </figure>
                </div>
              </div>
              
          </div>
        </div>
      </div>
    </nav>
  );
};

export { NavBar };
